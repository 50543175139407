/// <reference path="../lib/jquery.d.ts" />

/*
 * アンビエント宣言
 */

interface Window {
    chrome: String;

}
interface JQuery {
   
    customSelect(): any;
    chrome: any;
}
declare var WebFont:any;



/*
 * Entry Point
 */

$(() => {

   // console.log(common._ua);

    // ウェブフォント
    loadwebfont();

    // カスタムセレクトがある場合は実行
    if ($('select.customstyled').length > 0) {
        $('select.customstyled').customSelect();
    }

    //アーティクルページの場合のタイトル移動
    if ($('.mainttl').length > 0) {
        ArticleTitleHandler.initialize();
    }

    common.jQueryWindow.on('load', function () {
        Nav.initialize();
        ScrollHandler.initialize();
       
    });

});

/*
 * Modules
 */



////////////////////////////////////////////////////
// ウインドウ
module common {

    export var defaultWindow: Window = window;
    export var jQueryWindow: JQuery = $(window);

    // UA取得
    export var _ua = (() => {
        return {
            ltIE8: typeof window.addEventListener === "undefined" && typeof document.getElementsByClassName === "undefined",
            Webkit: typeof window.chrome === "undefined" && "WebkitAppearance" in document.documentElement.style,
            Mobile: typeof window.orientation !== "undefined"
        };
    })();

    export function getMediaQueryWindowWidth() {
        if (common._ua.Webkit && !common._ua.Mobile) {
            // Mac Safariのみ
            var windowWidth = common.jQueryWindow.width();
        } else {
            var windowWidth = common.defaultWindow.innerWidth;
        }
        return windowWidth;

    }

}

////////////////////////////////////////////////////
// ウェブフォント
	
function loadwebfont() {
    WebFont.load({
        custom: {
            families: ['noto-b', 'noto-r']
        },
        timeout: 5000,
        loading: function () { 
            // ロードが開始されたとき 
        },
        active: function () { 
            // Web Fontが使用可能になったとき 
        },
        inactive: function () { 
            // ブラウザがサポートしていないとき 
        },
        fontloading: function (fontFamily: String, fontDescription: String) { 
             // fontFamilyのロードが開始されたとき 
        },
        fontactive: function (fontFamily: String, fontDescription: String) { 
            // fontFamilyが使用可能になったとき 
        },
        fontinactive: function (fontFamily: String, fontDescription: String) { 
            // fontFamilyをブラウザがサポートしていないとき 
            $('.wf-loading').css('visibility', 'visible');
        }
    });
}


////////////////////////////////////////////////////
// ナビ

module Nav {
    export class NavObject {
        /////////////////////////////////////////////////////////////
        // variables

        private navStructure: JQuery = $('nav');
        private btn: JQuery = $('#nav-btn');
        private navOpen: Boolean = false;
        private breakPoint: Number = 560;
        private duration: Number = 300;

        /////////////////////////////////////////////////////////////
        // constructor

        constructor() {
            // console.log(this.btn.length);
            // console.log(this.navStructure.length);

            this.navStructure.show();
            this.navStructure.css({ right: - this.navStructure.width() });

            if (!common._ua.ltIE8) {
                this.btn.on('click',(e) => { this.clickHandler(e); return false; });
                common.jQueryWindow.on('resize',(e) => { this.mainResize(); });
                this.mainResize();
            }
        }

        /////////////////////////////////////////////////////////////
        // function

        // クリック
        private clickHandler(evt:Event) {
            if (this.navOpen) {
                this.navOpen = false;
                this.navStructure.stop(true, false).animate({ right: - this.navStructure.width() }, this.duration);
                this.btn.removeClass('active');
            } else {
                this.navOpen = true;
                this.navStructure.stop(true, false).animate({ right: 0 }, this.duration);
                this.btn.addClass('active');
            }
        }

        // ナビのレスポンシブ対応
        private mainResize() {
            if (this.navOpen) {

            } else {
                this.navStructure.css({ right: - this.navStructure.width() });
            }
        }
    }

    export function initialize() {
        var nav: NavObject = new NavObject();
    }
}

////////////////////////////////////////////////////
// スクロール

module ScrollHandler {
    export class ScrollHandlerObject {
        /////////////////////////////////////////////////////////////
        // variables

        private scrollTarget: JQuery = $('a[href^="#"]');
        private speed: number = 400; // ミリ秒(この値を変えるとスピードが変わる)
        private header: JQuery = $('header');
        private body: JQuery = $('body,html');

        /////////////////////////////////////////////////////////////
        // constructor


        constructor() {
           
            this.scrollTarget.on('click',(e) => { this.clickHandler(e); return false;});
        }

        /////////////////////////////////////////////////////////////
        // function

        // クリック
        private clickHandler(evt: Event) {

            var headerHeight = this.header.height();
            var href = $(evt.currentTarget).attr("href");
            var target = $(href == "#" || href == "" ? 'html' : href);
            var position = target.offset().top - headerHeight;

            this.body.animate({ scrollTop: position }, this.speed, 'swing');
        }
    }


    export function initialize() {
        var structure: ScrollHandlerObject = new ScrollHandlerObject();
    }
}

////////////////////////////////////////////////////
// スクロール

module ArticleTitleHandler {
    export class HandlerObject {
        /////////////////////////////////////////////////////////////
        // variables

        private title: JQuery = $('.mainttl');
        private titleCont: JQuery = $('.main-detailblock');
        private target: JQuery = $('#content-purpose-inner');
        private breakPoint: Number = 560;

        /////////////////////////////////////////////////////////////
        // constructor


        constructor() {
            if (!common._ua.ltIE8) {
                common.jQueryWindow.on('resize',(e) => { this.mainResizeHandler(); });
                this.mainResizeHandler();
            }
        }

        /////////////////////////////////////////////////////////////
        // function

        // ナビのレスポンシブ対応
        private mainResizeHandler() {
            if (common.getMediaQueryWindowWidth() > this.breakPoint) {
                this.titleCont.prepend(this.title);
            } else {
                this.target.before(this.title);
            }
        }
    }


    export function initialize() {
        var structure: HandlerObject = new HandlerObject();
    }
}